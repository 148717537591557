import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Button from "@mui/material/Button";

function importAll(r) {
    let images = {};
     r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    return images
   }
  const images = importAll(require.context('./images/Article', false, /\.(png|jpe?g|svg)$/));

const newsItems =[
  
    {
      desc: 'Zhidong won the first place in the CEAT 3MT competition',
      date: 'Monday, Oct 09  2023',
      id: 28,
      image: images['zhidong_3mt.png']
    },
    {
      type: 'Award',
      date: 'Friday, September 6 2019',
      desc: 'ASCC Awarded NSF Grant for Home Service Robots',
      id: 1,
      image: images['default.png']
    },
    {
      type: 'Award',
      date: 'Friday, July 26 2019',
      desc: 'Dr. Sheng was awarded a National Science Foundation project titled: FW-HTF-P: Robotic Health Assistants',
      id: 2,
      image: images['default.png']
    },
    {
      type: 'Update',
      date: 'Friday, May 31 2019',
      desc: '2019 STEM Robotics Summer Camp Update',
      id: 3,
      image: images['ha_kids.jpg']
    },
    {
      desc: 'Kids from local Cubscouts visited ASCC Lab on Oct. 16th, 2015.',
      date: 'Sunday, October 25 2015',
      id: 4,
      image: images['2015-10-16 19.04.07.jpg']
    },
    {
      desc: 'Trung Nguyen won Top 10 Finalist in 5th Annual App Competition.',
      date: 'Friday, April 28 2017',
      id: 5,
      image: images['TrungApp.jpg']
    },
    {
      desc: 'A reporter from The Oklahoman visited the lab',
      date: 'Sunday, November 26 2017',
      id: 6,
      image: images['2017Dailyok.jpg']
    },
    {
      desc: 'Our Cloud-based Smart Home Environment (CoSHE) on Singularityhub',
      id: 7,
      date: 'Wednesday, January 31 2018',
      image: images['default.png']
    },
    {
      desc: 'Minh Pham and Yehenew Mengistu won the first prize in Rural Healthcare Innovation Weekend, Nov 13th-15th, 2015 in Tulsa, Oklahoma',
      id: 8,
      date: 'Friday, November 20 2015',
      image: images['23111717752_0ab35c9f40_o.jpg']
    },
    {
      desc: 'Local high school students visit ASCC Lab',
      date: 'Monday, October 6 2014',
      id: 9,
      image: images['default.png']
    },
    {
      desc: 'K-12 students visited ASCC lab',
      date: 'Sunday, November 26 2017',
      id: 10,
      image: images['2017k12.jpg']
    },
    {
      desc: 'IEEE-CYBER 2016 Best Poster Award',
      date: 'Monday, July 11 2016',
      id: 11,
      image: images['Duy_award.jpg']
    },
    {
      desc: 'Gordon Cooper STEM Day',
      date: 'Wednesday, October 24 2018',
      id: 12,
      image: images['stemday.png']
    },
    {
      desc: 'ENDEAVOR Grand Opening',
      date: 'Saturday, September 22 2018',
      id: 13,
      image: images['endeavor1.png']
    },
    {
      desc: 'Dr. Sheng won the Second Place in the Competition of the 2018 President’s Cup for Creative Interdisciplinarity',
      date: 'Tuesday, December 18 2018',
      id: 14,
      image: images['2018PresidentCup1.jpg']
    },
    {
      desc: 'Director of Aging Services, State of Oklahoma, visited ASCC Lab',
      date: 'Monday, December 5 2016',
      id: 15,
      image: images['Nov302016_5.jpg']
    },
    {
      desc: `Congratulations on Ye Gu's Ph.D graduation`,
      date: 'Tuesday, May 5 2015',
      id: 16,
      image: images['default.png']
    },
    {
      desc: 'Bookworms Companion Robot Demo',
      date: 'Tuesday, September 25 2018',
      id: 17,
      image: images['bookworms.png']
    },
    {
      desc: `Barath Lakshmanan and his fellow Intel Interns won 2nd place of the PayPal Hack'on Competition`,
      date: 'Friday, November 20 2015',
      id: 18,
      image: images['default.png']
    },
    {
      desc: 'Barath has won the Robert & Jean Schuetz Graduate Fellowship in CEA',
      date: 'Tuesday, April 14 2015',
      id: 19,
      image: images['default.png']
    },
    {
      desc: `ASCC lab's students attended CASE 2016 Conference`,
      date: 'Wednesday, August 31 2016',
      id: 20,
      image: images['case2016_1.jpg'],
    },
    {
      desc: 'ASCC Lab’s Research Mentioned in The Journal Record',
      date: 'Monday, March 11 2019',
      id: 21,
      image: images['default.png']
    },
    {
      desc: 'ASCC Lab participated in the 2018 OSU National Lab Day',
      date: 'Tuesday, May 15 2018',
      id: 22,
      image: images['NLD2018 (1).jpg']
    },
    {
      desc: 'ASCC Lab participated in the 2016 OSU National Lab Day',
      date: 'Friday, May 13 2016',
      id: 23,
      image: images['NLD2016_1.jpg']
    },
    {
      desc: 'ASCC Lab participated in the 2015 OSU National Lab Day',
      date: 'Tuesday, May 12 2015',
      id: 24,
      image: images['NLD2015_3.jpg']
    },
    {
      desc: 'ASCC Demos Companion Robot to Honors Students',
      date: 'Wednesday, February 20 2019',
      id: 25,
      image: images['asccdemos.png']
    },
    {
      desc: 'ASCC Assists in Junior Day',
      date: 'Saturday, April 13 2019',
      id: 26,
      image: images['juniorday.png']
    },
    {
      desc: '2019 Summer STEM Robotics Camp',
      date: 'Tuesday, May 7 2019',
      id: 27,
      image: images['2019 RA Flyer FINAL_001.png']
    }
    
]


export default function News() {
  const navigate= useNavigate();
    return (
        <Box>
            <Typography variant='h4' sx={{mb: 2, mt: 2}} align='center'>
                NEWS
            </Typography>
            <Grid container spacing={2} colums={12} sx={{ml: {md: 10, xs: 1}, mb: {md: 2, xs: 4}}}>
                {newsItems.map((news) => (
                <Grid item md={4} xs={12} sm={12}>
                <Card elevation={5} sx={{ maxWidth: {md: 400, xs: 350}, borderRadius: 5}}>
                    <CardActionArea>
                        <CardMedia
                        component="img"
                        height="200"
                        image={news.image}
                        alt=""
                        />
                        <CardContent>
                        {/* <Typography gutterBottom variant="h5" component="div">
                            {news.type}
                        </Typography> */}
                        <Typography variant="body2" color="text.secondary">
                            {news.date}
                        </Typography>
                        <Typography sx={{ 
                            width: {md: 300},
                            height: 25,
                            whiteSpace: 'nowrap',
                            display: 'inline-block', 
                            overflow: 'hidden !important', 
                            textOverflow: 'ellipsis'
                        }} 
                        variant="body1" color="text.secondary">
                            {news.desc}
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button onClick={() => navigate('/Newspost?id='+ news.id )} size="small">Read More</Button>
                    </CardActions>
                    </Card>
                </Grid>
                ))}
            </Grid>
        </Box>
    )
}