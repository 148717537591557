import * as React from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';




const newsItems =[
  // {
  //   type: 'Award',
  //   date: 'Friday, September 6 2019',
  //   desc: 'ASCC Awarded NSF Grant for Home Service Robots',
  //   id: 1,
  // },

  {
    desc: 'Zhidong won the first place in the CEAT 3MT competition',
    date: 'Monday, Oct 09  2023',
    id: 28,
  },
  {
    type: 'Award',
    date: 'Friday, July 26 2019',
    desc: 'Dr. Sheng was awarded a National Science Foundation project titled: FW-HTF-P: Robotic Health Assistants',
    id: 2,
  },
  {
    type: 'Update',
    date: 'Friday, May 31 2019',
    desc: '2019 STEM Robotics Summer Camp Update',
    id: 3,
  },
  {
    desc: 'Kids from local Cubscouts visited ASCC Lab on Oct. 16th, 2015.',
    date: 'Sunday, October 25 2015',
    id: 4,
  },
  {
    desc: 'Trung Nguyen won Top 10 Finalist in 5th Annual App Competition.',
    date: 'Friday, April 28 2017',
    id: 5,
  },
  {
    desc: 'A reporter from The Oklahoman visited the lab',
    date: 'Sunday, November 26 2017',
    id: 6,
  },
  {
    desc: 'Our Cloud-based Smart Home Environment (CoSHE) on Singularityhub',
    id: 7,
    date: 'Wednesday, January 31 2018',
  },
]


export default function Announcements() {
  const colors = "#F39224, #F37224";
  const navigate= useNavigate();
  return (
    <Box display="flex-end">
      <Typography variant='h4' align='center' sx={{mb: 2, mt: 2}}>
        News
      </Typography> 
      { newsItems.slice(0, 4).map((news) => (
      <Stack direction="row" justifyContent="end">
        {/* <Card elevation={20} sx={{ width: {md: 345, sm: '100%', xs: '100%'}, maxWidth: {md: 345, xs: '100%', sm: '100%'}, mb: 2,}}> */}
        <Card elevation={20} sx={{ width: {md: 345, sm: '100%', xs: '100%'}, maxWidth: {md: 345, xs: '100%', sm: '100%'}, mb: 2, background: `linear-gradient(136deg,${colors})`,}}>
          <CardContent>
            <Typography variant="body2" color="text.primary">
              {news.date}<br/>
              {news.desc}
            </Typography>
          </CardContent>
          <CardActions align="center">
            {/* <Button size="small">Share</Button> */}
            <Button onClick={() => navigate('/Newspost?id='+ news.id )} size="small"><Typography variant='paragraph' color='white'>Read More</Typography></Button>
          </CardActions>
        </Card>
      </Stack>
      ))}
    </Box>
  );
}