import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import sheng from './images/sheng1.jpg';

const theme = createTheme();

const faculty = [
  {
    name: 'Dr. Weihua Sheng',
    github: 'oliviertassinari',
    twitter: 'olivtassinari',
    flag: 'Professor',
    location: 'weihua.sheng@okstate.edu',
  },
];

const activeCore = [
  {
    name: 'Olivier Tassinari',
    github: 'oliviertassinari',
    twitter: 'olivtassinari',
    flag: 'v1.x co-creator',
    location: 'Paris, France',
  },
  {
    name: 'Matt Brookes',
    github: 'mbrookes',
    twitter: 'randomtechdude',
    flag: 'Pioneer',
    location: 'London, UK',
  },
  {
    name: 'Sebastian Silbermann',
    github: 'eps1lon',
    twitter: 'sebsilbermann',
    flag: 'Community team',
    location: 'Dresden, Germany',
  },
  {
    name: 'Damien Tassone',
    github: 'dtassone',
    twitter: 'madKakoO',
    flag: 'Enterprise team',
    location: 'London, UK',
  },
  {
    name: 'Marija Najdova',
    github: 'mnajdova',
    twitter: 'marijanajdova',
    flag: 'Community team',
    location: 'Skopje, North Macedonia',
  },
  {
    name: 'Danail Hadjiatanasov',
    github: 'DanailH',
    twitter: 'danail_h',
    flag: 'Enterprise team',
    location: 'Amsterdam, Netherlands',
  },
  {
    name: 'Josh Wooding',
    github: 'joshwooding',
    twitter: 'JoshWooding_',
    flag: 'Community member, J.P. Morgan',
    location: 'London, UK',
  },
];

const community = [
  {
    name: 'Dmitriy Kovalenko',
    github: 'dmtrKovalenko',
    twitter: 'dmtrKovalenko',
    flag: 'Date pickers',
    location: 'Kharkiv, Ukraine',
  },
  {
    name: 'Danica Shen',
    github: 'DDDDDanica',
    flag: '🇨🇳 Chinese docs',
    location: 'Ireland',
  },
  {
    name: 'Yan Lee',
    github: 'AGDholo',
    flag: '🇨🇳 Chinese docs',
    location: 'China',
  },
  {
    name: 'Jairon Alves Lima',
    github: 'jaironalves',
    flag: '🇧🇷 Brazilian docs',
    location: 'São Paulo, Brazil',
  },
  {
    name: 'Oleg Slobodskoi',
    github: 'kof',
    twitter: 'oleg008',
    flag: 'JSS',
    location: 'Berlin, Germany',
  },
];

const emeriti = [
  {
    name: 'Hai Nguyen',
    github: 'hai-cea',
    twitter: 'haicea',
    flag: 'v0.x creator',
    location: 'Dallas, Texas, US',
  },
  {
    name: 'Nathan Marks',
    github: 'nathanmarks',
    flag: 'v1.x co-creator',
    location: 'Toronto, ON',
  },
  {
    name: 'Kevin Ross',
    github: 'rosskevin',
    twitter: 'rosskevin',
    flag: 'Core focus',
    location: 'Franklin, Tennessee, US',
  },
  {
    name: 'Sebastian Sebald',
    github: 'sebald',
    twitter: 'sebastiansebald',
    flag: 'Core focus',
    location: 'Freiburg, Germany',
  },
  {
    name: 'Ken Gregory',
    github: 'kgregory',
    flag: 'Core focus',
    location: 'New Jersey, US',
  },
  {
    name: 'Tom Crockett',
    github: 'pelotom',
    twitter: 'pelotom',
    flag: 'Core focus',
    location: 'Los Angeles, California, US',
  },
  {
    name: 'Maik Marschner',
    github: 'leMaik',
    twitter: 'leMaikOfficial',
    flag: 'Core focus',
    location: 'Hannover, Germany',
  },
];

const useStyles = {
  details: {
    margin: theme.spacing(1, 1, 1, 0),
  },
  cover: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    margin: theme.spacing(2),
    borderRadius: '50%',
    flexShrink: 0,
    backgroundColor: theme.palette.background.default,
  },
  icon: {
    fontSize: 18,
    padding: theme.spacing(1),
  },
  container: {
    margin: theme.spacing(2, 0, 4),
  },
};

function Group({ title, members }) {
  return (
    <Box>
      <Typography gutterBottom component="h2" variant="h5">
        {title}
      </Typography>
      <Grid container spacing={2} sx={useStyles.container}>
        {members.map((member) => (
          <Grid key={member.name} item xs={12} md={3}>
            <Paper variant="outlined">
              <Grid container wrap="nowrap">
                <Grid item>
                  <CardMedia
                    component="img"
                    sx={useStyles.cover}
                    image={sheng}
                    title="Avatar"
                  />
                </Grid>
                <Grid item>
                  <Box sx={useStyles.details}>
                    <Typography component="h3" variant="h6">
                      {member.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {member.flag}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {member.location}
                    </Typography>
                    <Grid container>
                      {member.github && (
                        <IconButton
                          aria-label="github"
                          component="a"
                          href={`https://github.com/${member.github}`}
                          sx={useStyles.icon}
                        >
                          <GitHubIcon fontSize="inherit" />
                        </IconButton>
                      )}
                      {member.twitter && (
                        <IconButton
                          aria-label="twitter"
                          component="a"
                          href={`https://twitter.com/${member.twitter}`}
                          sx={useStyles.icon}
                        >
                          <TwitterIcon fontSize="inherit" />
                        </IconButton>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

Group.propTypes = {
  members: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

function Team() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ textAlign: 'center', margin: 4 }}>
        <Typography variant="h4">People</Typography>
        <Group title="Faculty" members={faculty} />
        <Group title="PhD Students" members={activeCore} />
        <Group title="Community Contributors" members={community} />
        <Group title="Community Emeriti" members={emeriti} />
      </Box>
    </ThemeProvider>
  );
}

export default Team;